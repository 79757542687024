import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  ISimplePaginationProps,
  IThunkRejectValue,
  RootState,
} from "../../types";
import {
  BillingIntentResponse,
  BillingState,
  IBillingInformation,
  ICloudCredit,
  IInvoice,
  IPaymentMethod,
  IPlanGroup,
  ISubscription,
  ISubscriptionApiData,
} from "../../types/billing";
import {
  deletePaymentMethodApi,
  downlodInvoiceApi,
  getBillingInformationApi,
  getCloudCreditApi,
  getPlansApi,
  getSubscriptionsApi,
  invoicesApi,
  makeCreditOrderApi,
  makeBillingIntentApi,
  payNowByCloudCreditApi,
  payNowByDefaultCardApi,
  payNowByIntentApi,
  paymentMethodsApi,
  setAsDefaultPaymentMethodApi,
  topupWithPromoApi,
  updateBillingInformationApi,
  updateSubscriptionsApi,
  waitingStatusApi,
  activeWithPromoApi,
} from "../../apis/billingAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

export const invoiceCompletedStatus = ["ORDER_COMPLETED", "FinalSuccess"];
export const editablePlanGroupIds = [1, 2, 3, 4];

const initialState: BillingState = {
  cloudCredit: null,
  cloudCreditLoading: false,

  makeIntentLoading: false,
  loadingCardDetail: false,
  actionLoading: false,
  activeWithPromoLoading: false,

  paymentMethods: [],
  paymentMethodsLoading: false,

  paymentMethod: null,

  invoices: [],
  invoicesPagination: {
    Total: null,
    PageNumber: 1,
    PageSize: 5,
  },
  invoicesLoading: false,

  subscriptions: [],
  subscriptionsLoading: false,
  subscriptionActionLoading: false,

  // planGroups: [],
  // planGroupsLoading: false,
  plans: [],
  plansLoading: false,

  billingInformation: null,
  billingInformationLoading: false,
  billingInformationActionLoading: false,
  billingInformationModal: false,

  topupModal: false,
  topupWithPromoModal: false,
  topupLoading: false,

  selectedInvoiceForPay: null,
  payByCloudCreditLoading: false,
  payByDefaultCardLoading: false,
  payByIntentLoading: false,
};

export const getCloudCreditAsync = createAsyncThunk<
  ICloudCredit,
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "billing/cloud-credit",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId }
  ) => {
    try {
      getCloudCreditAsync.pending(requestId, { withoutLoading });

      const responce = await getCloudCreditApi();
      const credit = responce.data.Result;
      return fulfillWithValue(credit);
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const makeBillingIntentAsync = createAsyncThunk<
  BillingIntentResponse,
  undefined,
  IThunkRejectValue
>("billing/intent", async (_, { rejectWithValue }) => {
  try {
    const responce = await makeBillingIntentApi();
    return responce.data.Result;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const activeWithPromoAsync = createAsyncThunk<
  BillingIntentResponse,
  { PromoCode: string },
  IThunkRejectValue
>("billing/active-with-promo", async ({ PromoCode }, { rejectWithValue }) => {
  try {
    const responce = await activeWithPromoApi(PromoCode);
    return responce.data.Result;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const waitingStatusAsync = createAsyncThunk<
  any,
  { token: string },
  IThunkRejectValue
>("billing/waiting-status", async ({ token }, { rejectWithValue }) => {
  try {
    const responce = await waitingStatusApi(token);
    return responce.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const makeCreditOrderAsync = createAsyncThunk<
  { intentData: BillingIntentResponse },
  { amount: number },
  IThunkRejectValue
>(
  "billing/make-credit-order",
  async ({ amount }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const responce = await makeCreditOrderApi(amount);
      const data = responce.data.Result;
      return fulfillWithValue({ intentData: data });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const topupWithPromoAsync = createAsyncThunk<
  boolean,
  { code: string },
  IThunkRejectValue
>("billing/top-up-with-promo", async ({ code }, { rejectWithValue }) => {
  try {
    await topupWithPromoApi(code);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

// export const createPaymentMethodAsync = createAsyncThunk<
//   BillingIntentResponse,
//   undefined,
//   IThunkRejectValue
// >("billing/create-paymnt-method", async (_, { rejectWithValue }) => {
//   try {
//     const responce = await createPaymentMethodApi();
//     return responce.data.Result;
//   } catch (e) {
//     return rejectWithValue({ message: getExtractErrors(e) });
//   }
// });

export const setAsDefaultPaymentMethodAsync = createAsyncThunk<
  boolean,
  number,
  IThunkRejectValue
>("billing/set-as-default", async (paymentMethodId, { rejectWithValue }) => {
  try {
    await setAsDefaultPaymentMethodApi(paymentMethodId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const paymentMethodsAsync = createAsyncThunk<
  { paymentMethods: Array<IPaymentMethod> },
  undefined,
  IThunkRejectValue
>(
  "billing/payment-methods",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const responce = await paymentMethodsApi();
      const paymentMethods = responce.data.Result;
      return fulfillWithValue({ paymentMethods });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const invoicesAsync = createAsyncThunk<
  {
    invoices: Array<IInvoice>;
    invoicesPagination: ISimplePaginationProps;
  },
  undefined | { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "billing/invoices",
  async (
    inputData,
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    if (inputData && inputData.withoutLoading) {
      dispatch(
        invoicesAsync.pending("", {
          withoutLoading: true,
        })
      );
    }

    try {
      const {
        billing: {
          invoicesPagination: { PageNumber, PageSize },
        },
      } = getState() as RootState;

      const responce = await invoicesApi({ PageNumber, PageSize });

      const { Result, Pagination } = responce.data;
      return fulfillWithValue({
        invoices: Result,
        invoicesPagination: { ...Pagination, PageSize },
      });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getSubscriptionsAsync = createAsyncThunk<
  { subscriptions: Array<ISubscription> },
  { includePlans?: boolean },
  IThunkRejectValue
>(
  "billing/get-subscriptions",
  async ({ includePlans = false }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const responce = await getSubscriptionsApi(includePlans);
      const subscriptions = responce.data.Result;
      return fulfillWithValue({ subscriptions });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateSubscriptionsAsync = createAsyncThunk<
  boolean,
  { data: ISubscriptionApiData },
  IThunkRejectValue
>("billing/update-subscriptions", async ({ data }, { rejectWithValue }) => {
  try {
    await updateSubscriptionsApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getPlansAsync = createAsyncThunk<
  { plans: Array<IPlanGroup> },
  { planGroupId?: number; serviceType?: string | number },
  IThunkRejectValue
>(
  "billing/plans",
  async (
    { planGroupId, serviceType },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getPlansApi({ serviceType, planGroupId });
      const plans = response.data.Result;
      return fulfillWithValue({ plans });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

// export const getPlanGroupsAsync = createAsyncThunk<
//   { planGroups: Array<IPlanGroup> },
//   undefined,
//   IThunkRejectValue
// >("billing/plan-groups", async (_, { rejectWithValue, fulfillWithValue }) => {
//   try {
//     const response = await getPlanGroupsApi();
//     const planGroups = response.data.Result;
//     return fulfillWithValue({ planGroups });
//   } catch (e) {
//     return rejectWithValue({ message: getExtractErrors(e) });
//   }
// });

export const getBillingInformationAsync = createAsyncThunk<
  { information: IBillingInformation },
  undefined,
  IThunkRejectValue
>("billing/informations", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getBillingInformationApi();
    const information = response.data.Result;
    return fulfillWithValue({ information });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateBillingInformationAsync = createAsyncThunk<
  boolean,
  { data: any },
  IThunkRejectValue
>("billing/update-informations", async ({ data }, { rejectWithValue }) => {
  try {
    await updateBillingInformationApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deletePaymentMethodAsync = createAsyncThunk<
  boolean,
  { paymentId: number },
  IThunkRejectValue
>("billing/delete/payment", async ({ paymentId }, { rejectWithValue }) => {
  try {
    await deletePaymentMethodApi(paymentId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const payNowByCloudCreditAsync = createAsyncThunk<
  boolean,
  { invoiceId: number },
  IThunkRejectValue
>(
  "billing/invoice/pay-by-cloud-credit",
  async ({ invoiceId }, { rejectWithValue }) => {
    try {
      await payNowByCloudCreditApi(invoiceId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const payNowByDefaultCardAsync = createAsyncThunk<
  boolean,
  { invoiceId: number },
  IThunkRejectValue
>(
  "billing/invoice/pay-by-default-card",
  async ({ invoiceId }, { rejectWithValue }) => {
    try {
      await payNowByDefaultCardApi(invoiceId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const payNowByIntentsync = createAsyncThunk<
  BillingIntentResponse,
  { invoiceId: number; saveCard: boolean; defaultCard: boolean },
  IThunkRejectValue
>(
  "billing/invoice/intent",
  async ({ invoiceId, saveCard, defaultCard }, { rejectWithValue }) => {
    try {
      const responce = await payNowByIntentApi(
        invoiceId,
        saveCard,
        defaultCard
      );
      return responce.data.Result;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const downlodInvoiceAsync = createAsyncThunk<
  any,
  { invoiceNumber: number },
  IThunkRejectValue
>(
  "billing/invoice/download",
  async ({ invoiceNumber }, { rejectWithValue, fulfillWithValue }) => {
    try {
      console.log("Downloading invoice with ID:", invoiceNumber);
      const response = await downlodInvoiceApi(invoiceNumber);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", `${invoiceNumber}.pdf`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    handleChangeInvoicePagination: (
      state,
      action: PayloadAction<{ pageNumber?: number }>
    ) => {
      if (action.payload.pageNumber) {
        state.invoicesPagination = {
          ...state.invoicesPagination,
          PageNumber: action.payload.pageNumber,
        };
      }
    },
    handleShowBillingInformationModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.billingInformationModal = action.payload;
    },
    handleShowTopupModal: (state, action: PayloadAction<boolean>) => {
      state.topupModal = action.payload;
    },
    handleShowTopupWithPromoModal: (state, action: PayloadAction<boolean>) => {
      state.topupWithPromoModal = action.payload;
    },
    handleConfigPayNowModal: (
      state,
      action: PayloadAction<IInvoice | null>
    ) => {
      state.selectedInvoiceForPay = action.payload;
    },
    handleClearPlans: (state) => {
      state.plans = [];
      state.plansLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCloudCreditAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.cloudCreditLoading = true;
      })
      .addCase(getCloudCreditAsync.fulfilled, (state, { payload }) => {
        state.cloudCredit = payload;
        state.cloudCreditLoading = false;
      })
      .addCase(getCloudCreditAsync.rejected, (state, { payload }) => {
        state.cloudCreditLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(makeBillingIntentAsync.pending, (state) => {
        state.makeIntentLoading = true;
      })
      .addCase(makeBillingIntentAsync.fulfilled, (state) => {
        state.makeIntentLoading = false;
      })
      .addCase(makeBillingIntentAsync.rejected, (state, { payload }) => {
        state.makeIntentLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(topupWithPromoAsync.pending, (state) => {
        state.topupLoading = true;
      })
      .addCase(topupWithPromoAsync.fulfilled, (state) => {
        state.topupLoading = false;
      })
      .addCase(topupWithPromoAsync.rejected, (state, { payload }) => {
        state.topupLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(waitingStatusAsync.pending, (state) => {
        state.loadingCardDetail = true;
      })
      .addCase(waitingStatusAsync.fulfilled, (state, action) => {
        state.loadingCardDetail = false;
      })
      .addCase(waitingStatusAsync.rejected, (state, { payload }) => {
        state.loadingCardDetail = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(activeWithPromoAsync.pending, (state) => {
        state.activeWithPromoLoading = true;
      })
      .addCase(activeWithPromoAsync.fulfilled, (state) => {
        state.activeWithPromoLoading = false;
      })
      .addCase(activeWithPromoAsync.rejected, (state, { payload }) => {
        state.activeWithPromoLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(makeCreditOrderAsync.pending, (state) => {
        state.topupLoading = true;
      })
      .addCase(makeCreditOrderAsync.fulfilled, (state) => {
        state.topupLoading = false;
      })
      .addCase(makeCreditOrderAsync.rejected, (state, { payload }) => {
        state.topupLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    // builder
    //   .addCase(createPaymentMethodAsync.pending, (state) => {
    //     state.actionLoading = true;
    //   })
    //   .addCase(createPaymentMethodAsync.fulfilled, (state, action) => {
    //     state.actionLoading = false;
    //   })
    //   .addCase(createPaymentMethodAsync.rejected, (state, { payload }) => {
    //     state.actionLoading = false;
    //     if (payload?.message)
    // toast.error(() => CustomErrorToast(payload?.message));
    //   });

    builder
      .addCase(setAsDefaultPaymentMethodAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(setAsDefaultPaymentMethodAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(
        setAsDefaultPaymentMethodAsync.rejected,
        (state, { payload }) => {
          state.actionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(paymentMethodsAsync.pending, (state) => {
        state.paymentMethodsLoading = true;
      })
      .addCase(paymentMethodsAsync.fulfilled, (state, action) => {
        state.paymentMethodsLoading = false;
        state.paymentMethods = action.payload.paymentMethods;
      })
      .addCase(paymentMethodsAsync.rejected, (state, { payload }) => {
        state.paymentMethodsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(invoicesAsync.pending, (state, action) => {
        if (!action.meta.arg?.withoutLoading) state.invoicesLoading = true;
      })
      .addCase(
        invoicesAsync.fulfilled,
        (state, { payload: { invoices, invoicesPagination } }) => {
          state.invoicesLoading = false;
          state.invoices = invoices;
          state.invoicesPagination = invoicesPagination;
        }
      )
      .addCase(invoicesAsync.rejected, (state, { payload }) => {
        state.invoicesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getSubscriptionsAsync.pending, (state) => {
        state.subscriptionsLoading = true;
      })
      .addCase(getSubscriptionsAsync.fulfilled, (state, { payload }) => {
        state.subscriptionsLoading = false;
        state.subscriptions = payload.subscriptions;
      })
      .addCase(getSubscriptionsAsync.rejected, (state, { payload }) => {
        state.subscriptionsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateSubscriptionsAsync.pending, (state) => {
        state.subscriptionActionLoading = true;
      })
      .addCase(updateSubscriptionsAsync.fulfilled, (state, { payload }) => {
        state.subscriptionActionLoading = false;
      })
      .addCase(updateSubscriptionsAsync.rejected, (state, { payload }) => {
        state.subscriptionActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    //plan groups
    // builder
    //   .addCase(getPlanGroupsAsync.pending, (state) => {
    //     state.planGroupsLoading = true;
    //   })
    //   .addCase(
    //     getPlanGroupsAsync.fulfilled,
    //     (state, { payload: { planGroups } }) => {
    //       state.planGroupsLoading = false;
    //       state.planGroups = planGroups;
    //     }
    //   )
    //   .addCase(getPlanGroupsAsync.rejected, (state, { payload }) => {
    //     state.planGroupsLoading = false;
    //     if (payload?.message)
    //       toast.error(() => CustomErrorToast(payload?.message));
    //   });

    //plans
    builder
      .addCase(getPlansAsync.pending, (state) => {
        state.plansLoading = true;
      })
      .addCase(getPlansAsync.fulfilled, (state, { payload: { plans } }) => {
        state.plansLoading = false;
        if (plans.length) state.plans = plans[0].PlanDetail;
      })
      .addCase(getPlansAsync.rejected, (state, { payload }) => {
        state.plansLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBillingInformationAsync.pending, (state) => {
        state.billingInformationLoading = true;
      })
      .addCase(getBillingInformationAsync.fulfilled, (state, { payload }) => {
        state.billingInformationLoading = false;
        state.billingInformation = payload.information;
      })
      .addCase(getBillingInformationAsync.rejected, (state, { payload }) => {
        state.billingInformationLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBillingInformationAsync.pending, (state) => {
        state.billingInformationLoading = true;
      })
      .addCase(updateBillingInformationAsync.fulfilled, (state) => {
        state.billingInformationLoading = false;
      })
      .addCase(updateBillingInformationAsync.rejected, (state, { payload }) => {
        state.billingInformationLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deletePaymentMethodAsync.pending, (state) => {
        state.paymentMethodsLoading = true;
      })
      .addCase(deletePaymentMethodAsync.fulfilled, (state) => {
        state.paymentMethodsLoading = false;
        state.paymentMethod = null;
      })
      .addCase(deletePaymentMethodAsync.rejected, (state, { payload }) => {
        state.paymentMethodsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(payNowByCloudCreditAsync.pending, (state) => {
        state.payByCloudCreditLoading = true;
      })
      .addCase(payNowByCloudCreditAsync.fulfilled, (state) => {
        state.payByCloudCreditLoading = false;
      })
      .addCase(payNowByCloudCreditAsync.rejected, (state, { payload }) => {
        state.payByCloudCreditLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(payNowByDefaultCardAsync.pending, (state) => {
        state.payByDefaultCardLoading = true;
      })
      .addCase(payNowByDefaultCardAsync.fulfilled, (state) => {
        state.payByDefaultCardLoading = false;
      })
      .addCase(payNowByDefaultCardAsync.rejected, (state, { payload }) => {
        state.payByDefaultCardLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(payNowByIntentsync.pending, (state) => {
        state.payByIntentLoading = true;
      })
      .addCase(payNowByIntentsync.fulfilled, (state) => {
        state.payByIntentLoading = false;
      })
      .addCase(payNowByIntentsync.rejected, (state, { payload }) => {
        state.payByIntentLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    // builder
    //   .addCase(downlodInvoiceAsync.pending, (state) => {
    //     state.selectedInvoiceLoading = true;
    //   })
    //   .addCase(downlodInvoiceAsync.fulfilled, (state) => {
    //     state.selectedInvoiceLoading = false;
    //   })
    //   .addCase(downlodInvoiceAsync.rejected, (state, { payload }) => {
    //     state.selectedInvoiceLoading = false;
    //     if (payload?.message)
    // toast.error(() => CustomErrorToast(payload?.message));
    //   });
  },
});

// export const selectIsOpenCardDetailModal = (state: RootState) =>
//   state.billing.isOpenCardDetailModal;

export const selectCloudCredit = (state: RootState) =>
  state.billing.cloudCredit;
export const selectCloudCreditLoading = (state: RootState) =>
  state.billing.cloudCreditLoading;

export const selectMakeIntentLoading = (state: RootState) =>
  state.billing.makeIntentLoading;
export const selectActiveWithPromoLoading = (state: RootState) =>
  state.billing.activeWithPromoLoading;
export const selectLoadingCardDetail = (state: RootState) =>
  state.billing.loadingCardDetail;
export const selectPaymentMethodActionLoading = (state: RootState) =>
  state.billing.actionLoading;
export const selectPaymentMethods = (state: RootState) =>
  state.billing.paymentMethods;
export const selectPaymentMethodsLoading = (state: RootState) =>
  state.billing.paymentMethodsLoading;
export const selectPaymentMethod = (state: RootState) =>
  state.billing.paymentMethod;

export const selectInvoices = (state: RootState) => state.billing.invoices;
export const selectInvoicesPagination = (state: RootState) =>
  state.billing.invoicesPagination;
export const selectInvoicesLoading = (state: RootState) =>
  state.billing.invoicesLoading;

export const selectSubscriptions = (state: RootState) =>
  state.billing.subscriptions;
export const selectSubscriptionsLoading = (state: RootState) =>
  state.billing.subscriptionsLoading;
export const selectSubscriptionActionLoading = (state: RootState) =>
  state.billing.subscriptionActionLoading;

// export const selectPlanGroups = (state: RootState) => state.billing.planGroups;
// export const selectPlanGroupsLoading = (state: RootState) =>
//   state.billing.planGroupsLoading;
export const selectPlans = (state: RootState) => state.billing.plans;
export const selectPlansLoading = (state: RootState) =>
  state.billing.plansLoading;

export const selectBillingInformation = (state: RootState) =>
  state.billing.billingInformation;
export const selectBillingInformationLoading = (state: RootState) =>
  state.billing.billingInformationLoading;
export const selectBillingInformationActionLoading = (state: RootState) =>
  state.billing.billingInformationActionLoading;
export const selectShowBillingInformationModal = (state: RootState) =>
  state.billing.billingInformationModal;

// export const selectTopupValue = (state: RootState) => state.billing.topupValue;
export const selectTopupLoading = (state: RootState) =>
  state.billing.topupLoading;
export const selectShowTopupModal = (state: RootState) =>
  state.billing.topupModal;
export const selectShowTopupWithPromoModal = (state: RootState) =>
  state.billing.topupWithPromoModal;

//pay now
export const selectSelectedInvoiceForPay = (state: RootState) =>
  state.billing.selectedInvoiceForPay;
export const selectPayByCloudCreditLoading = (state: RootState) =>
  state.billing.payByCloudCreditLoading;
export const selectPayByDefaultCardLoading = (state: RootState) =>
  state.billing.payByDefaultCardLoading;
export const selectPayByIntentLoading = (state: RootState) =>
  state.billing.payByIntentLoading;

export const {
  handleChangeInvoicePagination,
  handleShowBillingInformationModal,
  handleShowTopupModal,
  handleShowTopupWithPromoModal,
  handleConfigPayNowModal,
  handleClearPlans,
} = billingSlice.actions;

export default billingSlice.reducer;
