import { useDappFilesContext } from "./contexts/DappFilesContext";
import FileExplorerSection from "./sections/FileExplorerSection";
import CompilerSection from "./sections/CompilerSection";
import DeploySection from "./sections/DeploySection";

const Sections = () => {
  const { activeSection } = useDappFilesContext();

  if (activeSection === "editor") return <FileExplorerSection />;
  if (activeSection === "compiler") return <CompilerSection />;
  if (activeSection === "deploy") return <DeploySection />;

  return null;
};

export default Sections;
