import { ReactComponent as FileExplorerIcon } from "./../../../../assets/icons/editor-files/document-duplicate.svg";
import { ReactComponent as CompilerIcon } from "./../../../../assets/icons/editor-files/wrench-screwdriver.svg";
import { ReactComponent as DeployIcon } from "./../../../../assets/icons/editor-files/rocket-launch.svg";
import { ReactComponent as ArrowPathIcon } from "./../../../../assets/icons/editor-files/arrow-path.svg";
import { ReactComponent as CommandPaletteIcon } from "./../../../../assets/icons/command-line.svg";
import { ReactComponent as DjunoLogo } from "./../../../../assets/icons/logo.svg";
import { Tooltip, cn } from "djuno-design";
import { useDappFilesContext } from "./contexts/DappFilesContext";

const Sidebar = () => {
  const {
    activeSection,
    actionLoading,
    compileLoading,
    changeActiveSection,
    dirtySolFiles,
    isFullScreen,
    editorIsAvailable,
    openCommandPalette,
  } = useDappFilesContext();

  return (
    <div className="flex flex-col items-center justify-between h-full bg-white dark:bg-dark-3 border-r dark:border-r-dark-2 pt-5">
      <div className="w-full flex flex-col items-center gap-5">
        {isFullScreen && <DjunoLogo className="w-6 h-6 flex-shrink-0 mb-4" />}
        <Tooltip content="File Explorer" className="!text-xs" place="top-start">
          <div
            className="group cursor-pointer"
            onClick={() => changeActiveSection("editor")}
          >
            <FileExplorerIcon
              className={cn(
                "w-6 h-6 text-slate-900 dark:text-slate-100 opacity-40 group-hover:opacity-100 transition-all duration-300 flex-shrink-0",
                { "!opacity-100 !text-blue-600": activeSection === "editor" }
              )}
            />
          </div>
        </Tooltip>

        <Tooltip
          content="Solidity compiler"
          className="!text-xs"
          place="top-start"
        >
          <div
            className="group cursor-pointer relative"
            onClick={() => changeActiveSection("compiler")}
          >
            <CompilerIcon
              className={cn(
                "w-6 h-6 text-slate-900 dark:text-slate-100 opacity-40 group-hover:opacity-100 transition-all duration-300 flex-shrink-0",
                { "!opacity-100 !text-blue-600": activeSection === "compiler" }
              )}
            />
            {dirtySolFiles.length > 0 && (
              <ArrowPathIcon
                className={cn(
                  "w-4 h-4 flex-shrink-0 absolute right-2.5 lg:right-2 -bottom-1 rounded-full bg-blue-500 text-slate-200 p-0.5",
                  { "animate-spin": actionLoading || compileLoading }
                )}
              />
            )}
          </div>
        </Tooltip>
        <Tooltip
          content="Deploy & run transactions"
          className="!text-xs"
          place="top-start"
        >
          <div
            className="group cursor-pointer"
            onClick={() => changeActiveSection("deploy")}
          >
            <DeployIcon
              className={cn(
                "w-6 h-6 text-slate-900 dark:text-slate-100 opacity-40 group-hover:opacity-100 transition-all duration-300 flex-shrink-0",
                { "!opacity-100 !text-blue-600": activeSection === "deploy" }
              )}
            />
          </div>
        </Tooltip>
      </div>
      <div className="w-full flex flex-col items-center gap-5">
        <div
          className={cn(
            "w-full flex justify-center group py-1 transition-all duration-300",
            {
              "cursor-pointer bg-primary-400 hover:bg-primary-500":
                editorIsAvailable,
            }
          )}
          onClick={() => {
            if (editorIsAvailable) openCommandPalette();
          }}
        >
          <Tooltip
            content="Command palette"
            className="!text-xs"
            place="top-start"
          >
            <div>
              <CommandPaletteIcon
                className={cn(
                  "w-5 h-5 text-slate-900 dark:text-slate-100 opacity-40 flex-shrink-0",
                  {
                    "!opacity-100 !text-slate-100 group-hover:opacity-100":
                      editorIsAvailable,
                  }
                )}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
