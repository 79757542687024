import { cn, Input, Loading, Tooltip, Typography } from "djuno-design";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import {
  DappFile,
  NewFileType,
  useDappFilesContext,
} from "../contexts/DappFilesContext";

import { ReactComponent as FileIcon } from "./../../../../../assets/icons/editor-files/languages/plaintext.svg";
import { ReactComponent as NewFolderIcon } from "./../../../../../assets/icons/editor-files/new-folder.svg";
import { ReactComponent as NewFileIcon } from "./../../../../../assets/icons/editor-files/new-file.svg";
import { ReactComponent as ArrowUpTrayIcon } from "./../../../../../assets/icons/editor-files/arrow-up-tray.svg";
import { ReactComponent as SaveIcon } from "./../../../../../assets/icons/editor-files/cloud-arrow-up.svg";
import { ReactComponent as ArrowPathIcon } from "./../../../../../assets/icons/editor-files/arrow-path.svg";
import { ReactComponent as Trashcon } from "./../../../../../assets/icons/trash.svg";
// import { ReactComponent as BackDirIcon } from "./../../../../../assets/icons/back-dir.svg";
import { ReactComponent as ArrowDownIcon } from "./../../../../../assets/icons/arrow-down.svg";

import { getDirectoryPath, getFileIcon } from "../utils";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ScrollArea } from "../../../../general/ScrollArea";
import { DeleteModal } from "../../../../modals/QuestionModal";
import toast from "react-hot-toast";

interface TreeViewComponentProps extends React.HTMLAttributes<HTMLDivElement> {}

type TreeViewProps = {
  initialSelectedId?: string;
  indicator?: boolean;
  initialExpandedItems?: string[];
} & TreeViewComponentProps;

interface FolderComponentProps {}

type FolderProps = {
  file: DappFile;
  isSelectable?: boolean;
} & FolderComponentProps;

const MAX_FILE_SIZE_MB = 20; // Max file size in MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes

const FileExplorerSection = () => {
  const {
    files,
    handleFileUpload,
    dirtyFiles,
    saveAllDirtyFiles,
    actionLoading,
    compileLoading,
    loadCurrentPath,
    selectedFile,
    setNewFileData,
    deleteFile,
  } = useDappFilesContext();

  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const oversizedFiles = Array.from(files).filter(
      (file) => file.size > MAX_FILE_SIZE_BYTES
    );

    if (oversizedFiles.length > 0) {
      toast.error(
        `The following files exceed the size limit of ${MAX_FILE_SIZE_MB}MB:\n` +
          oversizedFiles
            .map(
              (file) =>
                `- ${file.name} (${(file.size / (1024 * 1024)).toFixed(2)} MB)`
            )
            .join("\n")
      );
      return;
    }

    try {
      // Use the context function to handle the upload
      for (let i = 0; i < files.length; i++) {
        await handleFileUpload(files[i]);
      }
      console.log("File(s) uploaded successfully");
      // Optionally, refresh or fetch the updated file list here
    } catch (error) {
      console.error("Failed to upload file(s):", error);
    } finally {
      // Clear the input value to allow re-uploading the same file
      event.target.value = "";
    }
  };

  const renderFileTree = (files: DappFile[]) => {
    return files.map((file) => {
      if (file.isDirectory) {
        return (
          <Folder key={file.path} file={file}>
            {file.children && renderFileTree(file.children)}
          </Folder>
        );
      } else {
        return <File key={file.path} file={file} />;
      }
    });
  };

  const handleStartCreateNewFile = useCallback(
    (type: NewFileType) => {
      const dir = getDirectoryPath(selectedFile);
      console.log({ dir });
      setNewFileData({
        name: "",
        dir,
        type,
      });
    },
    [selectedFile, setNewFileData]
  );

  return (
    <>
      <Typography.Text size="sm" className="whitespace-nowrap px-3 mt-5">
        File explorer
      </Typography.Text>
      <div className="flex gap-2 px-3 my-4">
        <Tooltip className="!text-xs" content="New file" place="top-start">
          <NewFileIcon
            className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
            onClick={() => handleStartCreateNewFile("file")}
          />
        </Tooltip>
        <Tooltip className="!text-xs" content="New folder" place="top-start">
          <NewFolderIcon
            className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
            onClick={() => handleStartCreateNewFile("folder")}
          />
        </Tooltip>
        <Tooltip
          className="!text-xs"
          content="Open a File from your File System"
          place="top-start"
        >
          <label>
            <ArrowUpTrayIcon className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50" />
            <input
              type="file"
              multiple
              className="hidden"
              onChange={handleUpload}
            />
          </label>
        </Tooltip>
        {dirtyFiles.length > 0 && (
          <Tooltip className="!text-xs" content="Save" place="top-start">
            <SaveIcon
              className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
              onClick={saveAllDirtyFiles}
            />
          </Tooltip>
        )}

        <Tooltip className="!text-xs" content="Delete" place="top-start">
          <Trashcon
            className={cn(
              "w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50",
              {
                "!cursor-not-allowed !opacity-60":
                  !selectedFile || selectedFile.path === "",
              }
            )}
            onClick={() => setDeleteFileModal(true)}
          />
        </Tooltip>

        <Tooltip className="!text-xs" content="Refresh" place="top-start">
          <ArrowPathIcon
            className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
            onClick={loadCurrentPath}
          />
        </Tooltip>
        {(actionLoading || compileLoading) && (
          <Loading borderSize={2} uiSize={14} />
        )}
      </div>
      <Tree>{renderFileTree(files)}</Tree>

      <DeleteModal
        isOpen={deleteFileModal}
        onConfirm={async () => {
          if (selectedFile && selectedFile.path !== "") {
            const status = await deleteFile(selectedFile.path);
            if (status) {
              setDeleteFileModal(false);
            }
          }
        }}
        onClose={() => setDeleteFileModal(false)}
        confirmButtonType="danger"
        loading={actionLoading}
      />
    </>
  );
};

const Tree = forwardRef<HTMLDivElement, TreeViewProps>(
  ({ className, children, ...props }, ref) => {
    const [bordered, setBordered] = useState(false);
    const { expandedFilesPath, clearSelectedFilePath, newFileData } =
      useDappFilesContext();

    return (
      <ScrollArea
        ref={ref}
        className={cn(
          "tree w-full flex-1 border border-transparent transition-colors duration-300",
          { "!border-primary-400": bordered },
          className
        )}
        scrollbarClassName="!w-1.5"
        onClick={(e) => {
          e.stopPropagation();
          clearSelectedFilePath();
          setBordered(true);
          setTimeout(() => {
            setBordered(false);
          }, 2000);
        }}
      >
        <AccordionPrimitive.Root
          {...props}
          type="multiple"
          dir="ltr"
          defaultValue={expandedFilesPath}
          value={expandedFilesPath}
          className="flex flex-col max-h-full pb-10"
          // onValueChange={(value) => handleAddExpandedFilesPath(value[0])}
        >
          {children}
          {newFileData.type && newFileData.dir === "" && <NewItemInput />}
        </AccordionPrimitive.Root>
      </ScrollArea>
    );
  }
);

const Folder = forwardRef<
  HTMLDivElement,
  FolderProps & React.HTMLAttributes<HTMLDivElement>
>(({ className, file, isSelectable = true, children, ...props }, ref) => {
  const {
    expandedFilesPath,
    selectedFile,
    loadingFilesPath,
    handleFileClick,
    newFileData,
  } = useDappFilesContext();

  const isSelected = selectedFile?.path === file.path;

  const isLoadingStage = useMemo(() => {
    if (loadingFilesPath === undefined) return false;
    return loadingFilesPath.includes(file.path);
  }, [file.path, loadingFilesPath]);

  return (
    <AccordionPrimitive.Item
      {...props}
      value={file.path}
      className="relative h-full overflow-hidden"
    >
      <AccordionPrimitive.Trigger
        className={cn(
          `flex items-center gap-1 w-full select-none py-1`,
          className,
          {
            "bg-blue-500/20 dark:bg-blue-600/80": isSelected,
            " hover:bg-gray-100 dark:hover:bg-dark-2": !isSelected,
            "cursor-pointer": isSelectable,
            "cursor-not-allowed opacity-50": !isSelectable,
          }
        )}
        disabled={!isSelectable}
        onClick={(e) => {
          e.stopPropagation();
          handleFileClick(file);
        }}
      >
        {isLoadingStage ? (
          <Loading borderSize={2} uiSize={12} className="!mx-0.5" />
        ) : (
          <ArrowDownIcon
            className={cn(
              "w-4 h-4 flex-shrink-0 text-slate-700 dark:text-slate-200 transition-all duration-200 -rotate-90",
              { "rotate-0": expandedFilesPath?.includes(file.path) }
            )}
          />
        )}

        <Typography.Text size="xs">{file.name}</Typography.Text>
      </AccordionPrimitive.Trigger>
      <AccordionPrimitive.Content className="relative h-full overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down group">
        {/* {file && <TreeIndicator aria-hidden="true" />} */}
        {newFileData.dir === file.path && newFileData.type && <NewItemInput />}
        <AccordionPrimitive.Root
          dir={"ltr"}
          type="multiple"
          className="pl-3 flex flex-col"
          defaultValue={expandedFilesPath}
          value={expandedFilesPath}
          // onValueChange={(value) => handleAddExpandedFilesPath(value[0])}
        >
          {children}
        </AccordionPrimitive.Root>
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
});

const File = forwardRef<
  HTMLButtonElement,
  {
    file: DappFile;
    isSelectable?: boolean;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ file, className, isSelectable = true, children, ...props }, ref) => {
  const { selectedFile, handleFileClick, isDirtyFile } = useDappFilesContext();
  const isSelected = selectedFile?.path === file.path;
  return (
    <button
      ref={ref}
      type="button"
      disabled={!isSelectable}
      className={cn(
        "w-full select-none py-1",
        {
          "bg-blue-500/20 dark:bg-blue-500/80": isSelected,
          "hover:bg-gray-100 dark:hover:bg-dark-2": !isSelected,
        },
        isSelectable ? "cursor-pointer" : "cursor-not-allowed opacity-50",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        handleFileClick(file);
      }}
      {...props}
    >
      <div className="flex items-center gap-1 w-full">
        {getFileIcon(file.name)}
        <Typography.Text
          size="xs"
          uiType={isDirtyFile(file.path) ? "danger" : undefined}
          className="whitespace-nowrap overflow-hidden text-ellipsis"
        >
          {file.name}
        </Typography.Text>
      </div>
    </button>
  );
});

const TreeIndicator = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "absolute left-2 h-full w-px rounded-md bg-muted py-3 duration-300 ease-in-out group-hover:bg-slate-200 group-hover:dark:bg-slate-dark-2 z-20",
        className
      )}
      {...props}
    />
  );
});

const NewItemInput = () => {
  const {
    newFileData,
    setNewFileData,
    handleCreateNewFile,
    getFolderContents,
  } = useDappFilesContext();

  const newFileInputRef = useRef<HTMLInputElement>(null);

  const { name, type, dir } = newFileData;
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState<string | undefined>();
  const [existingNames, setExistingName] = useState<string[]>([]);

  const handleCreate = async () => {
    if (type && !inputError) {
      setLoading(true);
      await handleCreateNewFile();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type) {
      setTimeout(() => newFileInputRef.current?.focus(), 10);
    }
  }, [type]);

  useEffect(() => {
    const names = getFolderContents(dir).map((f) => f.name);
    setExistingName(names);
  }, [dir, getFolderContents]);

  const handleOnChange = useCallback(
    (name: string) => {
      if (existingNames.length > 0 && existingNames.includes(name)) {
        setInputError(
          `A file or Folder ${name} already exists at this location. Please choose a different name.`
        );
      } else {
        setInputError(undefined);
      }
      setNewFileData((prev) => ({
        ...prev,
        name,
      }));
    },
    [existingNames, setNewFileData]
  );

  const handleOnBlur = useCallback(() => {
    setInputError(undefined);
    setNewFileData({
      dir: "",
      name: "",
      type: null,
    });
  }, [setNewFileData]);

  return (
    <div
      className={cn(
        "select-none cursor-pointer items-start gap-1 py-0.5 hidden pl-1",
        {
          "!flex": type,
          "!pl-3": dir !== "",
        }
      )}
    >
      {type === "folder" && (
        <ArrowDownIcon className="w-4 h-4 flex-shrink-0 -rotate-90 text-slate-800 dark:text-slate-100 mt-0.5" />
      )}
      {type === "file" && (
        <FileIcon className="w-4 h-4 flex-shrink-0 text-slate-800 dark:text-slate-100 mt-0.5" />
      )}
      <Input
        ref={newFileInputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleCreate();
          }
        }}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={handleOnBlur}
        value={name}
        uiType="simple"
        className="!h-5 dark:!bg-transparent !text-xs !px-0 !rounded-none"
        error={inputError}
        loading={loading}
        // containerClassName="!h-6"
      />
    </div>
  );
};

export default FileExplorerSection;
