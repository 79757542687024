import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "./../../hooks";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as ArrowTopRightIcon } from "./../../assets/icons/arrow-top-right-on-square.svg";
import {
  selectBlockchain,
  selectBlockchainLoading,
  selectBlockchainOverview,
  selectBlockchainOverviewLoading,
} from "./../../store/blockchain-studio/blockchainSlice";
import { selectBlockchainsActionLoading } from "./../../store/blockchain-studio/blockchainsSlice";
import { Link, useParams } from "react-router-dom";
import { Tag, Typography, Flex, Loading, Input, Button } from "djuno-design";
import { BlockchainSettingsUrl } from "../../utils/urls";
import toast from "react-hot-toast";

const BlockchainOverviewTab = () => {
  const { blockchainId } = useParams();

  const blockchain = useAppSelector(selectBlockchain);
  const blockchainLoading = useAppSelector(selectBlockchainLoading);

  const blockchainOverview = useAppSelector(selectBlockchainOverview);
  const blockchainOverviewLoading = useAppSelector(
    selectBlockchainOverviewLoading
  );

  const actionLoading = useAppSelector(selectBlockchainsActionLoading);

  const addToMetamask = () => {
    if (typeof window.ethereum !== "undefined") {
      if (blockchain && blockchainOverview) {
        window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x" + blockchain.ChainId.toString(16),
              chainName: blockchain.ServiceName,
              nativeCurrency: {
                name: "ETH",
                symbol: "ETH",
                decimals: 18,
              },
              rpcUrls: [blockchainOverview.Http],
              blockExplorerUrls: [blockchainOverview.Explorer],
            },
          ],
        });
      }
    } else {
      toast.error("Metamask is not installed");
    }
  };
  return (
    <>
      <Flex direction="col" className="gap-3 mb-5">
        <Flex items={{ md: "center" }} className="gap-2">
          {/* <Typography.Text size="sm" className="mr-2">
            {blockchain?.Name || ""}
          </Typography.Text> */}
          <Flex
            direction={{ default: "col", md: "row" }}
            items={{ default: "start", md: "start" }}
            className="gap-2"
          >
            <Flex items="center">
              {blockchain?.PlanName && (
                <Tag color="processing" className="mr-2">
                  {blockchain.PlanName}
                </Tag>
              )}
              <Tag color="success">
                Number of nodes: {blockchain?.ReplicaCounts}
              </Tag>
            </Flex>
            <Link
              className="cursor-pointer text-sky-500 hover:text-sky-600"
              // to={BlockchainPlanUrl(blockchainId)}
              to={BlockchainSettingsUrl(blockchainId)}
            >
              <Typography.Text
                uiType="transparent"
                size="sm"
                className="font-light flex items-center gap-0.5"
              >
                Upgrade your instance
                <ArrowRightIcon className="w-4 aspect-square" />
              </Typography.Text>
            </Link>
          </Flex>

          <div className="ml-auto">
            <Button
              uiType="light"
              disabled={
                blockchainLoading ||
                !blockchain ||
                blockchainOverviewLoading ||
                !blockchainOverview
              }
              onClick={addToMetamask}
            >
              Add to metamask
            </Button>
          </div>
        </Flex>

        {blockchainOverviewLoading && (
          <Flex items="center" justify="center" className="min-h-[300px]">
            <Loading borderSize={2} />
          </Flex>
        )}

        {!blockchainOverviewLoading && blockchainOverview && (
          <Flex direction="col" className="mt-5 gap-3">
            <div className="w-full grid grid-cols-12 gap-1 md:gap-4 items-center">
              <div className="col-span-12 md:col-span-2">
                <Typography.Text size="sm">jsonrpc-http</Typography.Text>
              </div>
              <div className="col-span-12 md:col-span-10">
                <Flex items="center" className="gap-2">
                  <Input
                    value={blockchainOverview.Http}
                    copyable
                    containerClassName="!w-full md:!w-1/2 lg:!w-1/3"
                  />
                  <Button
                    uiType="icon"
                    className="!px-2.5"
                    onClick={() => window.open(blockchainOverview.Http)}
                    tooltip={{
                      content: "Open in new tab",
                      className: "!text-xs",
                    }}
                  >
                    <ArrowTopRightIcon className="w-4 h-4 font-semibold" />
                  </Button>
                </Flex>
              </div>
            </div>

            <div className="w-full grid grid-cols-12 gap-1 md:gap-4 items-center">
              <div className="col-span-12 md:col-span-2">
                <Typography.Text size="sm">jsonrpc-ws</Typography.Text>
              </div>
              <div className="col-span-12 md:col-span-10">
                <Flex items="center" className="gap-2">
                  <Input
                    value={blockchainOverview.Ws}
                    copyable
                    containerClassName="!w-full md:!w-1/2 lg:!w-1/3"
                  />
                  <Button
                    uiType="icon"
                    className="!px-2.5"
                    onClick={() => window.open(blockchainOverview.Ws)}
                    tooltip={{
                      content: "Open in new tab",
                      className: "!text-xs",
                    }}
                  >
                    <ArrowTopRightIcon className="w-4 h-4 font-semibold" />
                  </Button>
                </Flex>
              </div>
            </div>

            <div className="w-full grid grid-cols-12 gap-1 md:gap-4 items-center">
              <div className="col-span-12 md:col-span-2">
                <Typography.Text size="sm">block-explorer</Typography.Text>
              </div>
              <div className="col-span-12 md:col-span-10">
                <Flex items="center" className="gap-2">
                  <Input
                    value={blockchainOverview.Explorer}
                    copyable
                    containerClassName="!w-full md:!w-1/2 lg:!w-1/3"
                  />
                  <Button
                    uiType="icon"
                    className="!px-2.5"
                    onClick={() => window.open(blockchainOverview.Explorer)}
                    tooltip={{
                      content: "Open in new tab",
                      className: "!text-xs",
                    }}
                  >
                    <ArrowTopRightIcon className="w-4 h-4 font-semibold" />
                  </Button>
                </Flex>
              </div>
            </div>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default BlockchainOverviewTab;
