import { ethers } from "ethers";
import { Alert, Button, cn, Loading, Tooltip, Typography } from "djuno-design";
import { DappFile, useDappFilesContext } from "./../contexts/DappFilesContext";
import { ReactComponent as FolderIcon } from "./../../../../../assets/icons/editor-files/languages/folder.svg";
import { ReactComponent as DeployIcon } from "./../../../../../assets/icons/editor-files/arrow-up-tray.svg";
import { useCallback, useEffect, useMemo } from "react";
import { getFileIcon } from "../utils";

const DeploySection = () => {
  const {
    wallectAccount,
    handleSetWalletAccount,
    compiledContracts,
    compileLoading,
    getFileContentToDeploy,
    changeActiveSection,
  } = useDappFilesContext();

  const isConnected = useMemo(() => wallectAccount !== "", [wallectAccount]);

  const connectWallet = async () => {
    if (!window.ethereum) {
      alert("MetaMask is not installed!");
      return;
    }

    const provider = new ethers.BrowserProvider(window.ethereum); // Updated for v6
    const accounts = await provider.send("eth_requestAccounts", []);
    handleSetWalletAccount(accounts[0]);
  };

  const disconnectWallet = () => {
    handleSetWalletAccount("");
  };

  const handleAccountChange = useCallback(
    (accounts: string[]) => {
      handleSetWalletAccount(accounts[0]);
    },
    [handleSetWalletAccount]
  );

  useEffect(() => {
    if (!window.ethereum) return;
    window.ethereum?.on("accountsChanged", handleAccountChange);
    return () => {
      window.ethereum?.removeListener("accountsChanged", handleAccountChange);
    };
  }, [handleAccountChange]);

  const deployContract = async (file: DappFile) => {
    if (!compiledContracts) return;
    if (!window.ethereum) {
      alert("MetaMask is not installed!");
      return;
    }
    const fileToDeployString = await getFileContentToDeploy(file);
    if (fileToDeployString) {
      const contract = JSON.parse(fileToDeployString);
      console.log(contract);

      const { abi, bytecode } = contract;
      // console.log(abi, bytecode);
      const provider = new ethers.BrowserProvider(window.ethereum); // Updated for v6
      const signer = await provider.getSigner();
      const factory = new ethers.ContractFactory(abi, bytecode, signer);
      const deployedContract = await factory.deploy();
      await deployedContract.waitForDeployment(); // Updated method for v6
      console.log(`Contract deployed at: ${deployedContract.target}`);
    }
  };

  return (
    <div className="flex flex-col w-full h-full py-5">
      <Typography.Text size="sm" className="whitespace-nowrap px-3">
        Deploy
      </Typography.Text>
      <div className="flex flex-col mt-4 h-full overflow-y-auto">
        <div className="flex flex-col px-4 mt-5">
          <Button
            uiType="primary"
            className="!w-full !justify-center"
            disabled={isConnected}
            onClick={connectWallet}
          >
            {wallectAccount
              ? `Connected: ${wallectAccount.slice(
                  0,
                  4
                )} ... ${wallectAccount.slice(-4)}`
              : "Connect Wallet"}
          </Button>

          {isConnected && (
            <div className="flex flex-col mt-4">
              <Button
                uiType="dangerLight"
                className="!w-full !justify-center"
                onClick={disconnectWallet}
              >
                Disconnect Wallet
              </Button>
            </div>
          )}

          {!isConnected && (
            <Alert className="!mt-4" uiType="info" showIcon>
              <Typography.Text className="!text-xs">
                Connect to Deploy
              </Typography.Text>
            </Alert>
          )}

          <div className="flex flex-col mt-8">
            <Typography.Text className="!text-xs">
              Compiled Contracts:
            </Typography.Text>
            <div className="flex flex-col mt-2">
              {compileLoading && (
                <Alert uiType="info" className="!text-xs">
                  <Loading borderSize={2} uiSize={14} />
                  <Typography.Text className="!text-xs ml-1">
                    Compiling
                  </Typography.Text>
                </Alert>
              )}
              {compiledContracts.length === 0 && !compileLoading && (
                <Alert uiType="warning" showIcon className="!text-xs">
                  <Typography.Text className="!text-xs">
                    No contracts compiled. Please{" "}
                    <Typography.Text
                      className="!text-xs text-blue-400 cursor-pointer"
                      underline
                      onClick={() => changeActiveSection("compiler")}
                    >
                      compile
                    </Typography.Text>{" "}
                    contracts first.
                  </Typography.Text>
                </Alert>
              )}
              {compiledContracts.map((file, i) => (
                <CompiledFileFileRow
                  key={i}
                  file={file}
                  ExtraActions={
                    <div className="">
                      <Tooltip className="!text-xs" content="deploy contract">
                        <DeployIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            if (wallectAccount !== "") {
                              deployContract(file);
                            }
                          }}
                          className={cn(
                            "w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-100",
                            { "!cursor-not-allowed": !isConnected }
                          )}
                        />
                      </Tooltip>
                    </div>
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompiledFileFileRow: React.FC<{
  file: DappFile;
  ExtraActions?: React.ReactNode;
}> = ({ file, ExtraActions }) => {
  const { activeFile, handleFileClick, isDirtyFile } = useDappFilesContext();
  return (
    <li
      key={file.path}
      onClick={() => handleFileClick(file)}
      className={cn(
        "select-none px-1 cursor-pointer flex items-center gap-1 py-1.5 hover:bg-gray-100 dark:hover:bg-dark-2 justify-between w-full",
        {
          "bg-gray-200 hover:!bg-gray-200 dark:bg-dark-2 hover:dark:!bg-dark-2":
            activeFile?.path === file.path,
        }
      )}
    >
      <div className="flex items-center gap-1 max-w-[75%]">
        {file.isDirectory ? (
          <FolderIcon className="w-3 h-3 flex-shrink-0" />
        ) : (
          getFileIcon(file.path)
        )}
        <Typography.Text
          size="xs"
          uiType={isDirtyFile(file.path) ? "danger" : undefined}
          className="whitespace-nowrap overflow-hidden text-ellipsis"
        >
          {file.name}
        </Typography.Text>
      </div>
      <div className="flex items-center gap-1">{ExtraActions}</div>
    </li>
  );
};

export default DeploySection;
