import { ReactComponent as JsIcon } from "./../../../../assets/icons/editor-files/languages/js.svg";
import { ReactComponent as JsxIcon } from "./../../../../assets/icons/editor-files/languages/tsx.svg";
import { ReactComponent as TsIcon } from "./../../../../assets/icons/editor-files/languages/ts.svg";
import { ReactComponent as CssIcon } from "./../../../../assets/icons/editor-files/languages/css.svg";
import { ReactComponent as HtmlIcon } from "./../../../../assets/icons/editor-files/languages/html.svg";
import { ReactComponent as JsonIcon } from "./../../../../assets/icons/editor-files/languages/json.svg";
import { ReactComponent as SolidityIcon } from "./../../../../assets/icons/editor-files/languages/solidity.svg";
import { ReactComponent as PlaintextIcon } from "./../../../../assets/icons/editor-files/languages/plaintext.svg";
import { ReactComponent as SvgIcon } from "./../../../../assets/icons/editor-files/languages/svg.svg";
import { ReactComponent as DockerIcon } from "./../../../../assets/icons/editor-files/languages/docker.svg";
import { ReactComponent as PythonIcon } from "./../../../../assets/icons/editor-files/languages/python.svg";
import { ReactComponent as JavaIcon } from "./../../../../assets/icons/editor-files/languages/java.svg";
import { ReactComponent as GoIcon } from "./../../../../assets/icons/editor-files/languages/go.svg";
import { ReactComponent as RubyIcon } from "./../../../../assets/icons/editor-files/languages/ruby.svg";
import { ReactComponent as PhpIcon } from "./../../../../assets/icons/editor-files/languages/php.svg";
import { ReactComponent as ShellIcon } from "./../../../../assets/icons/editor-files/languages/shell.svg";
import { ReactComponent as SqlIcon } from "./../../../../assets/icons/editor-files/languages/sql.svg";
import { ReactComponent as KotlinIcon } from "./../../../../assets/icons/editor-files/languages/kotlin.svg";
import { ReactComponent as SassIcon } from "./../../../../assets/icons/editor-files/languages/sass.svg";
import { ReactComponent as ScssIcon } from "./../../../../assets/icons/editor-files/languages/scss.svg";
import { ReactComponent as PerlIcon } from "./../../../../assets/icons/editor-files/languages/perl.svg";
import { ReactComponent as NginxIcon } from "./../../../../assets/icons/editor-files/languages/nginx.svg";
import { ReactComponent as PgsqlIcon } from "./../../../../assets/icons/editor-files/languages/pgsql.svg";
import { ReactComponent as YamlIcon } from "./../../../../assets/icons/editor-files/languages/yaml.svg";
import { ReactComponent as CIcon } from "./../../../../assets/icons/editor-files/languages/c.svg";
import { ReactComponent as CppIcon } from "./../../../../assets/icons/editor-files/languages/cpp.svg";
import { ReactComponent as CsharpIcon } from "./../../../../assets/icons/editor-files/languages/csharp.svg";
import { ReactComponent as DatabaseIcon } from "./../../../../assets/icons/editor-files/languages/db.svg";
import { ReactComponent as GitIcon } from "./../../../../assets/icons/editor-files/languages/git.svg";
import { ReactComponent as GitLabIcon } from "./../../../../assets/icons/editor-files/languages/gitlab.svg";
import { ReactComponent as RustIcon } from "./../../../../assets/icons/editor-files/languages/rust.svg";
import { ReactComponent as SqliteIcon } from "./../../../../assets/icons/editor-files/languages/sqlite.svg";
import { ReactComponent as VueIcon } from "./../../../../assets/icons/editor-files/languages/vue.svg";
import { ReactComponent as DjangoIcon } from "./../../../../assets/icons/editor-files/languages/django.svg";
import { DappFile } from "./contexts/DappFilesContext";

const specialFilenames: Array<string> = [
  "dockerfile",
  "Dockerfile",
  "DockerFile",
  "gitignore",
  "gitkeep",
];

export const getFileType = (path: string): string | undefined => {
  const filename = path.split("/").pop();
  const filenameArr = filename?.split(".").filter(Boolean);

  if (!filenameArr) return undefined;

  const fileName = filenameArr[0];
  const extension = filenameArr[1];

  return specialFilenames.includes(fileName) ? fileName : extension;
};

export const getLanguageFromFilename = (path: string): string => {
  let fileType = getFileType(path);

  switch (fileType) {
    case "js":
    case "jsx":
      return "javascript";
    case "ts":
    case "tsx":
      return "typescript";
    case "css":
      return "css";
    case "html":
    case "svg":
      return "html";
    case "json":
      return "json";
    case "sol":
      return "sol";
    case "py":
      return "python";
    case "java":
      return "java";
    case "go":
      return "go";
    case "rb":
      return "ruby";
    case "php":
      return "php";
    case "sh":
    case "bash":
      return "shellscript";
    case "sql":
      return "sql";
    case "kt":
    case "kts":
      return "kotlin";
    case "sass":
      return "scss";
    case "scss":
      return "scss";
    case "pl":
    case "pm":
      return "perl";
    // case "conf":
    //   return "nginx";
    case "pgsql":
      return "pgsql";
    case "yaml":
    case "yml":
      return "yaml";
    case "c":
      return "c";
    case "cpp":
    case "cc":
    case "cxx":
    case "h":
    case "hpp":
      return "cpp";
    case "cs":
      return "csharp";
    // case "db":
    //   return "database";
    // case "git":
    //   return "git";
    // case "gitlab-ci.yml":
    //   return "gitlab";
    case "rs":
      return "rust";
    case "sqlite":
      return "sqlite";
    case "vue":
      return "vue";
    default:
      return "plaintext";
  }
};

export const getFileIcon = (path: string): React.ReactNode => {
  let fileType = getFileType(path);

  if (!fileType) {
    return (
      <PlaintextIcon className="w-3 h-3 flex-shrink-0 dark:text-slate-300" />
    );
  }

  switch (fileType) {
    case "js":
      return <JsIcon className="w-4 h-4 flex-shrink-0" />;
    case "jsx":
      return <JsxIcon className="w-4 h-4 flex-shrink-0" />;
    case "ts":
      return <TsIcon className="w-4 h-4 flex-shrink-0" />;
    case "tsx":
      return <JsxIcon className="w-4 h-4 flex-shrink-0" />;
    case "css":
      return <CssIcon className="w-4 h-4 flex-shrink-0" />;
    case "html":
      return <HtmlIcon className="w-4 h-4 flex-shrink-0" />;
    case "json":
      return <JsonIcon className="w-4 h-4 flex-shrink-0" />;
    case "sol":
      return <SolidityIcon className="w-4 h-4 flex-shrink-0" />;
    case "svg":
      return <SvgIcon className="w-3 h-3 flex-shrink-0" />;
    case "py":
      return <PythonIcon className="w-3 h-3 flex-shrink-0" />;
    case "java":
      return <JavaIcon className="w-3 h-3 flex-shrink-0" />;
    case "go":
      return <GoIcon className="w-3 h-3 flex-shrink-0" />;
    case "rb":
      return <RubyIcon className="w-3 h-3 flex-shrink-0" />;
    case "php":
      return <PhpIcon className="w-3 h-3 flex-shrink-0" />;
    case "sh":
    case "bash":
      return <ShellIcon className="w-3 h-3 flex-shrink-0" />;
    case "sql":
      return <SqlIcon className="w-3 h-3 flex-shrink-0" />;
    case "kt":
    case "kts":
      return <KotlinIcon className="w-3 h-3 flex-shrink-0" />;
    case "sass":
      return <SassIcon className="w-3 h-3 flex-shrink-0" />;
    case "scss":
      return <ScssIcon className="w-3 h-3 flex-shrink-0" />;
    case "pl":
    case "pm":
      return <PerlIcon className="w-3 h-3 flex-shrink-0" />;
    case "conf":
      return <NginxIcon className="w-3 h-3 flex-shrink-0" />;
    case "pgsql":
      return <PgsqlIcon className="w-3 h-3 flex-shrink-0" />;
    case "yaml":
    case "yml":
      return <YamlIcon className="w-3 h-3 flex-shrink-0" />;
    case "c":
      return <CIcon className="w-3 h-3 flex-shrink-0" />;
    case "cpp":
    case "cc":
    case "cxx":
    case "h":
    case "hpp":
      return <CppIcon className="w-3 h-3 flex-shrink-0" />;
    case "cs":
      return <CsharpIcon className="w-3 h-3 flex-shrink-0" />;
    case "db":
      return <DatabaseIcon className="w-3 h-3 flex-shrink-0" />;
    case "git":
      return <GitIcon className="w-3 h-3 flex-shrink-0" />;
    // case "gitlab-ci.yml":
    //   return <GitLabIcon className="w-3 h-3 flex-shrink-0" />;
    case "rs":
      return <RustIcon className="w-3 h-3 flex-shrink-0" />;
    case "sqlite":
      return <SqliteIcon className="w-3 h-3 flex-shrink-0" />;
    case "vue":
      return <VueIcon className="w-3 h-3 flex-shrink-0" />;
    case "django":
      return <DjangoIcon className="w-3 h-3 flex-shrink-0" />;
    case "dockerfile":
    case "Dockerfile":
    case "DockerFile":
      return <DockerIcon className="w-3 h-3 flex-shrink-0" />;

    default:
      return (
        <PlaintextIcon className="w-4 h-4 flex-shrink-0 dark:text-slate-300" />
      );
  }
};

export function getDirectoryPath(file: DappFile | null): string {
  if (!file) return "";
  if (file.isDirectory) return file.path.replace(/^\/|\/$/g, "");
  const sanitizedPath = file.path.replace(/^\/|\/$/g, "");
  const sanitizedPathArr = sanitizedPath.split("/").filter(Boolean);
  if (sanitizedPathArr.length < 1) return "";
  const dirArr = sanitizedPathArr.slice(0, sanitizedPathArr.length - 1);
  return dirArr.join("/");
}

export function reduceFileName(fileName: string, maxLength: number = 30) {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const extensionIndex = fileName.lastIndexOf(".");
  const hasExtension = extensionIndex > 0;

  const extension = hasExtension ? fileName.slice(extensionIndex) : "";
  const baseName = hasExtension ? fileName.slice(0, extensionIndex) : fileName;

  const visibleChars = maxLength - 3 - extension.length;

  if (visibleChars <= 0) {
    // If maxLength is too small to show meaningful characters
    return fileName.slice(0, maxLength - 3) + "...";
  }

  const startChars = Math.ceil(visibleChars / 2);
  const endChars = Math.floor(visibleChars / 2);

  return (
    baseName.slice(0, startChars) +
    "..." +
    baseName.slice(-endChars) +
    extension
  );
}
