import { Alert, Button, cn, Typography } from "djuno-design";
import { useDappFilesContext } from "./../contexts/DappFilesContext";
import { getLanguageFromFilename } from "./../utils";
import { ReactComponent as ArrowPathIcon } from "./../../../../../assets/icons/editor-files/arrow-path.svg";
import { useMemo } from "react";
import MarkdownText from "../../../../general/MarkdownText";

const CompilerSection = () => {
  const {
    activeFile,
    compoleFile,
    actionLoading,
    compileLoading,
    compileOutput,
  } = useDappFilesContext();

  const disabledCompileButton = useMemo(
    () => getLanguageFromFilename(activeFile?.path || "") !== "sol",
    [activeFile?.path]
  );
  return (
    <div className="flex flex-col w-full h-full py-5">
      <Typography.Text size="sm" className="whitespace-nowrap px-3">
        Solidity Compiler
      </Typography.Text>
      <div className="flex flex-col mt-4 h-full overflow-y-auto">
        <div className="flex flex-col px-4 mt-5">
          <Button
            uiType="primary"
            className="!w-full !justify-center"
            // disabled={disabledCompileButton}
            // onClick={() =>
            //   activeFile &&
            //   !saveLoading &&
            //   !compileLoading &&
            //   compoleFile(activeFile)
            // }
            disabled={actionLoading && compileLoading}
            onClick={() => !actionLoading && !compileLoading && compoleFile()}
          >
            <div className="flex items-center justify-center gap-1 whitespace-nowrap truncate">
              <ArrowPathIcon
                className={cn("w-4 h-4 text-slate-200 flex-shrink-0", {
                  "animate-spin": actionLoading || compileLoading,
                  // "!text-slate-400": disabledCompileButton,
                })}
              />
              Compile
              {/* Compile {activeFile ? `: ${activeFile?.name}` : ""} */}
            </div>
          </Button>
        </div>

        {compileOutput && compileOutput.message && (
          <div className="mt-5 px-4">
            <Alert
              uiType={compileOutput.type}
              className="!max-w-full !overflow-x-auto"
            >
              <Typography.Text size="xs">
                <MarkdownText text={compileOutput.message} />
              </Typography.Text>
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompilerSection;
