import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getBucketDetailsAsync,
  selectBucketDetails,
  selectBucketDetailsLoading,
} from "../../store/s3/buckets/bucketSlice";
import { selectActionLoading } from "../../store/s3/buckets/bucketsSlice";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as RefreshIcon } from "./../../assets/icons/arrow-path.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as FolderOpenIcon } from "./../../assets/icons/folder-open.svg";
import { S3BucketUrl, S3BucketsUrl } from "../../utils/urls";
import S3BucketOverviewTab from "../../components/s3/buckets/settings/S3BucketOverviewTab";
import S3BucketEventsTab from "../../components/s3/buckets/settings/S3BucketEventsTab";
import S3BucketAccessLogsTab from "../../components/s3/buckets/settings/S3BucketAccessLogsTab";
import { DeleteModal } from "../../components/modals/QuestionModal";
import {
  deleteBucketAsync,
  getBucketsAsync,
} from "../../store/s3/buckets/bucketsSlice";
import S3BucketAnonymousTab from "../../components/s3/buckets/settings/S3BucketAnonymousTab";
import S3BucketLifecycleTab from "../../components/s3/buckets/settings/S3BucketLifecycleTab";
import S3BucketReplicationsTab from "../../components/s3/buckets/settings/S3BucketReplicationsTab";
import { Helmet } from "react-helmet";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { ReactComponent as BellIcon } from "./../../assets/icons/bell.svg";
import { ReactComponent as AnonymousIcon } from "./../../assets/icons/anonymous.svg";
import {
  Button,
  cn,
  Skeleton,
  TabOptions,
  Tabs,
  Typography,
} from "djuno-design";
import useServiceType from "../../hooks/useServiceType";

export const S3BucketSettinsMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    element: <S3BucketOverviewTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BellIcon className="w-3" />
        Events
      </div>
    ),
    element: <S3BucketEventsTab />,
    testId: "2th-tab",
  },
  {
    label: "Replications",
    element: <S3BucketReplicationsTab />,
    testId: "3th-tab",
  },
  {
    label: "Lifecycle",
    element: <S3BucketLifecycleTab />,
    testId: "4th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BeatIcon className="w-3" />
        Access logs
      </div>
    ),
    element: <S3BucketAccessLogsTab />,
    testId: "5th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <AnonymousIcon className="w-3" />
        Anonymous
      </div>
    ),
    element: <S3BucketAnonymousTab />,
    testId: "6th-tab",
  },
];

const S3BucketSettingPage = () => {
  const { bucketName } = useParams();
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);
  const [deleteBucketName, setDeleteBucketName] = useState<string | null>(null);
  const actionLoading = useAppSelector(selectActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { serviceType } = useServiceType(
    process.env.REACT_APP_SIMPLE_STORAGE_SERVICE_TYPE
  );

  useEffect(() => {
    if (bucketName) {
      dispatch(getBucketDetailsAsync({ name: bucketName }));
    }
  }, [bucketName, dispatch]);

  const handleDelete = () => {
    deleteBucketName &&
      dispatch(deleteBucketAsync({ name: deleteBucketName })).then((action) => {
        if (action.type === "buckets/delete/fulfilled") {
          dispatch(getBucketsAsync()).then((action) => {
            if (action.type === "buckets/fulfilled") {
              navigate(S3BucketsUrl);
            }
          });
        } else {
        }
        setDeleteBucketName(null);
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""} (
          {bucketDetails ? bucketDetails.name : ""} settings)
        </title>
        <meta
          name="description"
          content="Simple-storage technology refers to a straightforward method of storing data or information in a manner that is easy to implement and access"
        />
      </Helmet>
      <div className="flex w-full h-16">
        <div className="px-6 items-center justify-between flex w-full">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => navigate(S3BucketsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {bucketDetailsLoading && (
              <Skeleton style={{ width: 250, height: 26 }} />
            )}
            {!bucketDetailsLoading && (
              <Typography.Title
                level={5}
                className="!mb-0 !max-w-[200px] md:!max-w-[400px] lg:!max-w-[350px] xl:!max-w-[400px] !truncate"
              >
                {bucketDetails?.name}
              </Typography.Title>
            )}
          </div>
          <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
            <Button
              uiType="light"
              onClick={() => bucketName && navigate(S3BucketUrl(bucketName))}
              className="group"
              tooltip={{ content: "Browse bucket" }}
            >
              <FolderOpenIcon className="w-4 h-4 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              uiType="light"
              onClick={() =>
                bucketName &&
                dispatch(getBucketDetailsAsync({ name: bucketName }))
              }
              className="group"
              tooltip={{ content: "Refresh" }}
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              uiType="dangerLight"
              onClick={() => bucketName && setDeleteBucketName(bucketName)}
            >
              <span className="hidden md:block">Delete Bucket</span>
              <DeleteIcon className="w-4 h-4 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>
      </div>
      <div className="mx-6">
        <Tabs
          options={S3BucketSettinsMenus.map((o) => ({
            label: o.label,
            element: o.element,
            testId: o.testId,
          }))}
          panelClassName="!bg-transparent"
        />
      </div>

      <DeleteModal
        title="Delete Bucket"
        isOpen={deleteBucketName !== null}
        onClose={() => setDeleteBucketName(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmString={bucketDetails?.name}
        confirmButtonType="danger"
      />
    </>
  );
};
export default S3BucketSettingPage;
